
import { useReducer, useState } from 'react';
import './App.css';


const colorOptions = [
  {
    id: 0,
    colorDescription: "black",
    colorBackground: "black",
    colorGrid: "grey",
    colorText: "white",
  },
  {
    id: 1,
    colorDescription: "white",
    colorBackground: "white",
    colorGrid: "grey",
    colorText: "black",
  },
  {
    id: 2,
    colorDescription: "grey",
    colorBackground: "#D8D7DF",
    colorGrid: "white",
    colorText: "black",
  },
  {
    id: 3,
    colorDescription: "yellow",
    colorBackground: "#FAE053",
    colorGrid: "white",
    colorText: "black",
  },

  {
    id: 4,
    colorDescription: "blue",
    colorBackground: "#25a3eb",
    colorGrid: "lightblue",
    colorText: "white",
  },
  {
    id: 5,
    colorDescription: "green",
    colorBackground: "#67BA5E",
    colorGrid: "lightgreen",
    colorText: "white",
  },
  {
    id: 6,
    colorDescription: "pink",
    colorBackground: "#F59BBB",
    colorGrid: "white",
    colorText: "white",
  }
]


function ColorBtn(props) {
  return (
    <a href="#"
      className="colorBtn"
      id={props.id}
      style={{ backgroundColor: props.colorBackground, backgroundImage: props.colorGrid, top: props.top }}
      onClick={props.onClick}
    >
    </a>
  );
}

const Form = ({ onSubmit, onChange, formData, submitting }) => (
  <div>
    <form className="inputForm" onSubmit={onSubmit}>

      <input name="name" type="text" placeholder="name" onChange={onChange} value={formData.name} disabled={submitting} />
      <input name="email" type="email" placeholder="email" onChange={onChange} value={formData.email} disabled={submitting} />
      <input name="colorChoice" type="text" value={`chosen color: ${formData.colorName}`} onChange={onChange} disabled />


      <button id="submitBtn" type="submit" disabled={submitting}>keep me posted</button>
    </form >
  </div>
)


const formReducer = (state, event) => {
  if (event.reset) {
    return {
      name: '',
      email: '',
      colorName: '',
      colorHex: ''
    }
  }
  return {
    ...state,
    [event.name]: event.value
  }
}


function App() {
  // setting States:

  const [selectedColorId, setSelectedColorId] = useState(1);
  const initialColor = colorOptions.find(item => item.id === selectedColorId);

  const [submitting, setSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [formData, setFormData] = useReducer(formReducer, { 'colorName': initialColor['colorDescription'], 'colorHex': initialColor['colorBackground'] });

  const handleSubmit = event => {
    event.preventDefault();
    setSubmitting(true);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData)
    };

    fetch('/sendmail', requestOptions)
      .then(response => response.json())
      .then(response => {
        console.log(response);
        setSubmitting(false);
        if (response.success) {
          // hide form, show progress bar
          setFormData({
            reset: true
          });
          setIsSubmitted(true);
        }
        else {
          // ?? show error to the user
        }
      })

  }

  const handleChange = event => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  }


  const onColorBtnClick = (id) => {
    const colorBackground = colorOptions.find(item => item.id === id)['colorBackground']
    const colorGrid = colorOptions.find(item => item.id === id)['colorGrid']
    console.log(`ID: ${id}, Background: ${colorBackground}, Grid: ${colorGrid}`);
    // change color of background, grid) 
    document.body.style.backgroundColor = colorBackground;
    document.body.style.backgroundImage = `linear-gradient(to right, ${colorGrid} 1px, transparent 1px),
    linear-gradient(to bottom, ${colorGrid} 1px, transparent 1px)`;
    setSelectedColorId(id);

    const color = colorOptions.find(item => item.id === id);

    setFormData({ name: 'colorName', value: color['colorDescription'] })
    setFormData({ name: 'colorHex', value: color['colorBackground'] })
  }


  const getStyles = (colorId) => {
    const selectedStyles = colorOptions.find(item => item.id === colorId)

    const styles = {
      color: selectedStyles['colorText']
    }

    return styles
  }



  return (
    <div className="wrapper" style={getStyles(selectedColorId)}>
      <div className="left-side"></div>
      <div className="content" id="text" >
        <div className="logo">
          ORC-A
        </div>
        <div className="updates">
          coming summer 2022
        </div>
      </div>
      <div className="color-nav-container">

        {colorOptions.map(({ id, colorBackground }, i) => (
          <ColorBtn
            key={id}
            top={`${16 + i * 8}vh`}
            colorBackground={colorBackground}
            onClick={() => onColorBtnClick(id)}
          />
        ))}
      </div>
      <div className="footer">
        {!isSubmitted &&
          <div className="form">
            <div className="form-intro">get updates on the process</div>
            <Form
              onChange={handleChange}
              formData={formData}
              onSubmit={handleSubmit}
              submitting={submitting}
            />
          </div>
        }
        {isSubmitted &&
          <div className="confirmation">
            <div className="confirmation-text">
              <p>Thank you for your interest!</p><p>We are currently at IDEA development stage</p> <p>Let's keep in touch (<a href="mailto:orc-a@orc-a.ca">team ORC-A</a>)</p>
            </div>
          </div>
        }
      </div>
    </div>)
};

export default App;
